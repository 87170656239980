.App {
  text-align: center;
  font-family: Arial, sans-serif;
  color: #333;
  height: 100%;
}

.App-header {
  background-color: linear-gradient(to top right, black, rgb(41, 39, 39));
  /* padding: 20px; */
  color: white;
  float: left;
  text-align: left;
  padding-left: 5%;
  padding-right: 10%;
  width: 85%;
}

.App-main {
  padding: 40px;
  background: linear-gradient(to top right, black, rgb(41, 39, 39));
  color: white;
  flex: 1;
  justify-content: center;
  align-items: center;
  height: 80vh;
  color: gold;
}

.App-main p {
  height: 100%;
  justify-content: center;
  align-items: center;
  /* float: left; */
}

.App-footer {
  background: linear-gradient(to top right, black, black);
  padding: 2vh;
  color: white;
  position: fixed;
  width: 100%;
  bottom: 0;
}
.construction-image {
  height: 50%;
}
